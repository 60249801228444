import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

const routes = [{
    path: "/collect",
    name: "collect",
    component: () =>
        import ("../views/collect.vue"),
},{
    path: "/ok",
    name: "ok",
    component: () =>
        import ("../views/ok.vue"),
}
];


const router = new VueRouter({
    routes,
});

//解决 replace 或 push的冲突报错
const originalPush = router.push
const originalReplace = router.replace
router.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
    return originalPush.call(this, location).catch(err => err)
}
router.replace = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
    return originalReplace.call(this, location).catch(err => err)
}

// 路由守卫
router.beforeEach((to, from, next) => {
    try {
        // let isLogin = localStorage.getItem("token_type")
        // if (to.name === 'login') {
        //     if (isLogin) {
        //         router.push({ name: 'condition' });
        //     }
        // } else {
        //     if (!isLogin) {
        //         router.push({ name: 'login' })
        //     }
        // }
        next();
    } catch (error) {
        console.log('errR', error)
    }

})

export default router;
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import ElementUI from "element-ui";
import "./thematic/index.css";
import axios from "./interface/index";
import * as echarts from 'echarts'



Vue.config.productionTip = false;
Vue.prototype.axios = axios;
Vue.prototype.$echarts = echarts
Vue.use(ElementUI);

new Vue({
    router,
    render: (h) => h(App),
}).$mount("#app");
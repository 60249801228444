<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  created() {

  }
}
</script>

<style>
body {
  margin: 0;
  padding: 0;
  background: #F2F2F2;
}
</style>

import axios from "axios";
import { Message } from "element-ui";
import QS from "qs";
import router from "../router";

axios.defaults.baseURL = '/osprey'
axios.defaults.timeout = 4000;

// 响应拦截
axios.interceptors.response.use(
    (response) => {
        return response.data;
    },
    (error) => {
        let { response } = error;
        if (response) {
            if (response.status === 401) {
            } else {
                Message({
                    message: response.data.msg ? response.data.msg : '请求失败,请稍后重试',
                    type: "error",
                });
            }
        } else {
            if (!window.navigator.onLine) {
                Message({
                    message: "请检查网络！",
                    type: "error",
                });
            }
        }
        return Primise.reject(error);
    }
);

export function getOther(url, params) {
    return new Promise((resolve, reject) => {
        axios.get(url, {
            params: params
        }).then(res => {
            resolve(res.data);
        }).catch(err => {
            reject(err.data)
        })
    });
}

export function get(url, params) {
    return new Promise((resolve, reject) => {
        axios.get(url + '?' + QS.stringify(params)).then(res => {
            resolve(res.data);
        }).catch(err => {
            reject(err.data)
        })
    });
}

export function post(url, data) {
    return new Promise((resolve, reject) => {
        axios.post(url, data, {
            headers: {
                'content-type': 'application/json;charset=UTF-8'
            }
        }).then(res => {
            resolve(res.data);
        }).catch(err => {
            reject(err.data)
        })
    });
}

export function deleteFn(url, data) {
    return new Promise((resolve, reject) => {
        axios.delete(url + '/' + data).then(res => {
            resolve(res.data);
        }).catch(err => {
            reject(err.data)
        })
    });
}

export function putFn(url, data) {
    return new Promise((resolve, reject) => {
        axios.put(url, data).then(res => {
            resolve(res.data);
        }).catch(err => {
            reject(err.data)
        })
    });
}


export default axios